@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';































































































































































































































































@import "./Wallet.scss";

.Wallet_wrapper {
  .Wallet_skeleton {
    padding: 30px 0 5px;

    .v-skeleton-loader {
      .v-skeleton-loader__table-cell {
        width: 100%;
        margin-bottom: 15px;
        height: 75px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .v-skeleton-loader__text {
          height: 75px;
        }
      }
    }
  }
}

.wallet-footer-wrapper {
  position: relative;
  display: inline-block;

  .select-wrapper.select-wrapper--inputSelect {
    right: 0;
    top: 50%;
    transform: translate(95%, -51%);
    bottom: auto;
  }
}

.Wallet_footer--type-2 {
  padding: 14px 10px 10px;

  @media only screen and (min-width: $tablet-width) {
    padding: 9px 16px 16px 20px;
  }
}

.wallet-total-type-2 {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 6px;
  margin: 0 0 10px;

  &--top {
    margin: 0;
  }

  .select-wrapper--inputSelect {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .Select_root.v-select.v-text-field--solo > .v-input__control {
    min-height: 30px;
  }

  .ExtraFilter_InputArea {
    height: 35px;
    background: none;
  }
}
