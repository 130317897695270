@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';












































.exchange-heading {
  .exchange-tab {
    text-shadow: 0 0 10px #102032;
    flex: 1 0 0;

    &.exchange-tab-active {
      background: rgba(#2af3f3, 0.2);
      color: #2af3f3;
      transition: 0.2s all ease;
      flex: 0 0 38%;
    }
  }
}
