@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































































.exchange-skeleton {
  .v-skeleton-loader {
    .v-skeleton-loader__table-cell {
      width: 100%;
      margin-bottom: 15px;
      &:nth-of-type(1n) {
        height: 70px;
        .v-skeleton-loader__text {
          height: 70px;
        }
      }
      &:nth-of-type(2) {
        height: 30px;
        .v-skeleton-loader__text {
          height: 30px;
        }
      }
      &:nth-of-type(3) {
        height: 70px;
      }
      &:nth-of-type(4) {
        height: 70px;
      }
      &:nth-of-type(5) {
        height: 95px;
        margin-bottom: 0;
        .v-skeleton-loader__text {
          height: 95px;
        }
      }
    }
  }
}
