@import '@/assets/scss/_variables.scss';

.WalletItem_wrapper {
  display: grid;
  text-align: left;
  grid-template-areas: 'currency currency' 'total totalInAnother' 'reserved totalInAnother' 'buttons buttons';
  padding: 14px 0;

  @media only screen and (min-width: $mobile-width) {
    grid-template-areas: 'currency total' 'currency reserved' 'buttons totalInAnother';
  }

  @media only screen and (min-width: $large-width) {
    padding: 11px 0;
  }
}

.wallet-item-currency {
  grid-area: currency;
}

.WalletItem_button {
  margin: 0 6px 0 0;

  @media only screen and (min-width: $large-width) {  
    width: 100px;
    margin: 0 10px 0 0;
  }

  &:last-child {
    margin: 0;
  }
}

.WalletItem_total {
  grid-area: total;
}

.WalletItem_reserved {
  grid-area: reserved;
}

.WalletItem_totalInAnother {
  grid-area: totalInAnother;
}