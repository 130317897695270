@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























.EmptyState_root {
  .EmptyState_wrapper {
    position: relative;
    min-height: 400px;
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background-image: url('../../../assets/img/_common/kauri-big-logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      height: 250px;
      opacity: 0.2;
    }
    p {
      position: absolute;
      right: 50%;
      top: 40%;
      transform: translate(50%, -50%);
      font-size: 20px;
      font-weight: 400;
    }
  }
}
