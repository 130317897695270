@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































.dashboard-wallet-wrapper {
  @media screen and (min-width: 1170px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 1fr 441px;
  }
}
