@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';












































































































































































































@import '@/assets/scss/_variables.scss';
@import './HistoryDetails/HistoryDetails.scss';

.HistoryTable_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  row-gap: 8px;
  padding: 0 10px;

  @media only screen and (min-width: $tablet-width) {
    display: none;
  }

  .HistoryTable_head {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #2af3f3;
    opacity: 0.89;
    width: 25%;
    text-align: center;

    &:first-of-type {
      text-align: left;
    }
    &:nth-of-type(4) {
      text-align: right;
      padding-right: 14px;
    }
  }
}

.HistoryTable_filters {
  margin-bottom: 16px;

  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .HistoryTable_filter {
    margin-bottom: 10px;

    @media only screen and (min-width: $tablet-width) {
      flex: 0 1 calc(25% - 6px);
      margin: 0;
    }
  }

  // временно, исправить после согласования макета
  .text-field-input-root {
    cursor: pointer;

    .v-label {
      font-size: 16px;
      line-height: 18px;
      color: #fff;
    }
  }
}

.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    width: 100%;
    margin-bottom: 8px;

    .v-skeleton-loader__text {
      height: 50px;
    }
  }
}

.HistoryTable_filter-skeleton.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    height: 69px;

    .v-skeleton-loader__text {
      height: 69px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}
