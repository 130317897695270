@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























































































@import '@/assets/scss/_variables.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.exchange-btns {
  button {
    flex: 0 1 48%;
  }
}
