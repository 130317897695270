@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























































































































.m-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background: #2AF3F3;
  box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  border-radius: 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #2D4D70;

  @media (any-hover: hover) {
    &:hover {
      background: #a5ffff;
      box-shadow: 0px 0px 10px rgb(42 243 243 / 55%);
    }
  }
}
