@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































































































































@import '@/assets/scss/_variables.scss';

.HistoryDetails_container {
  &--grid {
    @media only screen and (min-width: $desktop-width) {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
    }
  }
}
.HistoryDetails_row {
  text-align: left;
  .HistoryDetails_label {
    font-size: 12px;
    line-height: 14px;
    color: #a6aebf;
    @media only screen and (min-width: $large-width) {
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .HistoryDetails_value {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-left: 3px;
    @media only screen and (min-width: $large-width) {
      font-size: 16px;
      line-height: 18px;
    }
    &--link {
      color: #2af1f1;
      text-decoration: none;
    }
  }
  .HistoryDetails_risk-panel {
    display: inline-block;
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2af3f3;
    padding: 5px;
    background: rgba(#2af3f3, 0.1);
    border-radius: 2px;
    @media only screen and (min-width: $large-width) {
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.HistoryDetails_col {
  &--medium {
    @media only screen and (min-width: $desktop-width) {
      border-left: 1px dashed rgba(#2af3f3, 0.21);
      border-right: 1px dashed rgba(#2af3f3, 0.21);
    }
  }
}

.HistoryDetails_column {
  height: 100%;
  padding: 10px;
  @media only screen and (min-width: $desktop-width) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 10px;
  }
}

.HistoryDetails_column-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #2af3f3;
  padding-bottom: 12px;
  width: 100%;
  @media only screen and (min-width: $large-width) {
    font-size: 14px;
    line-height: 16px;
  }
  @media only screen and (min-width: $wide-width) {
    font-size: 16px;
    line-height: 19px;
  }
}

.HistoryDetails_risk-part {
  flex: 1;
  width: 100%;
}

.HistoryDetails_risk-row {
  @media only screen and (min-width: $desktop-width) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
  height: 100%;
  .HistoryDetails_risk-name {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    @media only screen and (min-width: $desktop-width) {
      display: flex;
      align-items: center;
      text-align: right;
      max-width: calc(100% - 10px - 47px);
    }
    @media only screen and (min-width: $large-width) {
      font-size: 12px;
      line-height: 14px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 14px;
      line-height: 16px;
    }
    color: #ffffff;
    margin-right: 10px;
    word-break: inherit;
    span {
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      word-break: normal;
      margin-left: 5px;
      @media only screen and (min-width: $large-width) {
        font-size: 14px;
        line-height: 16px;
      }
      @media only screen and (min-width: $wide-width) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .HistoryDetails_risk-value {
    width: 47px;
    min-height: 1px;
    margin: 10px auto 0;
    @media only screen and (min-width: $desktop-width) {
      margin: 0;
    }
    &--high {
      background: #ff556d;
    }
    &--medium {
      background: #f0c854;
    }
    &--low {
      background: #5abc67;
    }
  }
}
