@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

.wallet-wrapper {
  height: calc((144px * 3) + 8px);

  @media only screen and (min-width: $mobile-width) {
    height: calc((93px * 4) + 8px);
  }
  @include scrollbar;
}

.Wallet_footer {
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-align: left;
  padding: 15px 20px 16px;
  position: relative;
  z-index: 1;
  @media only screen and (min-width: $desktop-width) {
    padding: 18px 20px;
  }
  @media only screen and (min-width: $large-width) {
    padding: 21px 20px;
  }
}

.Wallet_total {
  font-size: 18px;
  line-height: 21px;
  color: #2af3f3;
}
