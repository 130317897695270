@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































































































































@import '@/assets/scss/_variables.scss';
//.HistoryDetails_root {
//  border: 2px solid #213b57;
//  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
//  border-bottom-left-radius: 4px;
//  border-bottom-right-radius: 4px;
//}
//.HistoryDetails_wrapper {
//  padding: 19px 10px;
//  @media only screen and (min-width: $tablet-width) {
//    padding: 19px 0px 19px 15px;
//  }
//}
.HistoryDetails {
  &_id {
    color: #fff;
  }

  &_greenColor {
    color: #5ABC67;
  }

  &_redColor {
    color: #FF556D;
  }

  &_blueColor {
    color: #1C8AE4;
  }

  &_purpleColor {
    color: #A44FD5;
  }
}
