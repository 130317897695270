@import '@/assets/scss/_variables.scss';

.HistoryDetailsDefault_row {
    text-align: left;
    span {
      font-size: 12px;
      line-height: 14px;
      color: #fff;
      margin-right: 15px;
      @media only screen and (min-width: $large-width) {
        font-size: 14px;
        line-height: 16px;
      }
      @media only screen and (min-width: $wide-width) {
        font-size: 16px;
        line-height: 19px;
      }
      &:nth-child(odd) {
        margin-right: 5px;
        color: #a6aebf;
      }
    }
  }