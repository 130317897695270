@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































































































































































































































































































.Exchange_row {
  .exchange-input-relative {
    .multiselect {
      position: absolute;
      right: 10px;
      top: 0;
      width: 50px;

      .multiselect__select {
        padding: 0;
        margin: 0;
        width: auto;
        height: 100%;

        &:before {
          top: 60%;
        }
      }

      .multiselect__tags {
        border: none;
        background: transparent;
        padding: 0;
      }

      .multiselect__single {
        line-height: 46px;
        margin: 0;
        background: transparent;
        color: #fff;
      }

      .multiselect__content-wrapper {
        width: 60px;
        z-index: 5;

        .multiselect__option {
          padding: 5px 12px;
          min-height: 26px;
          background: rgba(42, 243, 243, 0.2);

          &--highlight {
            background: #102032;
          }

          &--selected {
            background: #50c5e9;
          }
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.Exchange_select {
  &.multiselect--disabled {
    opacity: 1;
    background: transparent;

    .multiselect__select {
      display: none;
    }
  }
}
