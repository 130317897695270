@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































































































































@import '@/assets/scss/_variables.scss';

.history-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 30px 30px;
  row-gap: 4px;

  @media only screen and (min-width: $tablet-width) {
    row-gap: 12px;
    grid-template-rows: 30px;
  }

  &--cancelled {
    opacity: 0.6;
  }

  .history-row-col {
    display: flex;
    align-items: center;
    //justify-content: center;
    font-size: 12px;
    line-height: 14px;
    //width: 25%;
    word-break: break-word;
    padding: 0 6px 0 0;

    &.one-sell {
      grid-column: span 2;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 1;
      }
    }

    &.two-sell {
      grid-column: span 2;
      grid-row: 2;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 2;
        grid-row: 1;
        justify-content: center;
      }
    }

    &.three-sell {
      grid-column: span 2;
      grid-row: 2;
      justify-content: flex-end;
      text-align: right;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 3;
        grid-row: 1;
        justify-content: center;
      }
    }

    &.four-sell {
      grid-column: span 2;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 4;
      }
    }

    @media only screen and (min-width: $large-width) {
      padding: 0 10px 0 0;
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 16px;
      line-height: 19px;
    }

    &:first-of-type {
      text-align: left;
      justify-content: flex-start;
    }

    &:last-of-type {
      padding: 0;
    }

    &:nth-of-type(3) {
      > * {
        font-size: 11px;
        @media only screen and (min-width: $tablet-width) {
          font-size: inherit;
        }
      }
    }

    &--status {
      text-align: right;
    }

    &--currency {
      display: block;

      @media only screen and (min-width: $tablet-width) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      //span {
      //  display: block;
      //  white-space: nowrap;
      //}
    }

    &--type {
      text-transform: capitalize;
    }

    .round-arrow-btn {
      flex-shrink: 0;
    }
  }
}

.history-row-status {
  &--completed {
    color: #fff;
    background-color: #5ABC67;
  }

  &--waiting {
    color: #F0C854;
    background-color: rgba(#F0C854, .1);
  }

  &--new{
    color: #2AF3F3;
    background-color: rgba(#2AF3F3, .1);
  }

  &--cancelled {
    color: #5ABC67;
    background-color: rgba(#5ABC67, .1);
  }

  &--cancelling {
    color: #5ABC67;
    background-color: #FFF;
  }

  &--error {
    color: #fff;
    background-color: #FF556D;
  }

  &--blocked {
    color: #FF556D;
    background-color: rgba(255, 85, 109, 0.1);
    border-color: #FF556D;
  }

  &--expired {
    background-color: rgba(#ff556d, 0.1);
    color: #ff556d;
  }
}
